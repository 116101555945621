import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  makeStyles,
  Grid,
  CardHeader,
  CircularProgress,
} from "@material-ui/core";
import { useStylesGlobal } from "configurations/styles/theme";
import ReferenceDocument from "../../General/ReferenceDocument";
import AssignmentIcon from "@material-ui/icons/Assignment";

import { useDispatch, useSelector } from "react-redux";
import { selectedResourceAction } from "redux/actions/resourcesActions";
import { getSub } from "redux/actions/userActions";
import {
  changeDateEmisionAction,
  changeDateAuthAction,
  changeNumDocAction,
  addRISEAction,
  changeErrorLabelsAction, 
  changeTypeDocAction
  
} from "redux/actions/debitNoteActions";
import HTTPAuth from "configurations/axios";
import apis from "../apis";
import validations from "../validations";


const useStyles = makeStyles((theme) => ({
  containerDebitNote: {
    borderRadius: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    boxShadow: "5px 3px 3px #aaaaaa",
    flexGrow: 1,
    marginBottom: "1%",
    padding: "0% 0% 0% 0%",
  },
}));

const DataDebitNote = () => {
  const classesView = useStyles();
  const classesGlobales = useStylesGlobal();
  const [secuencialComp, setSecuencialComp] = useState(null);
  const debitNote = useSelector((state) => state.debitNote);
  const tokenUser = useSelector((state) => state.user.tk);
  const dispatch = useDispatch();
  const addRise = (rise) => dispatch(addRISEAction(rise));
  const changeDateEmision = (date) => dispatch(changeDateEmisionAction(date));
  const changeTypeDoc = (typeDoc) => dispatch(changeTypeDocAction(typeDoc));
  const changeDateAuth = (date) => dispatch(changeDateAuthAction(date));
  const changeNumDoc = (numDoc) => dispatch(changeNumDocAction(numDoc));
  const changeErrors = (error) => dispatch(changeErrorLabelsAction(error));
  const redirectHome = () =>
    dispatch(
      selectedResourceAction({
        id: "INI",
        titlePage: "Inicio",
      })
    );

  useEffect(() => {
    const tk = tokenUser ? tokenUser : "";
    HTTPAuth.get(`${apis.get_secuencial_user}/${getSub(tk)}/05`)
      .then((response) => {
        const data = response.data;
        if (typeof data === "string" && data !== null) {
          setSecuencialComp(data);
        }
      })
      .catch((error) => {
        if (error.response) {
          Swal.fire(
            "",
            "Se produjo un problema al consultar su secuencial. Contáctese con el administrador del Sistema.",
            "error"
          ).then((response) => {
            if (response.isConfirmed) {
              redirectHome();
            }
          });
        } else {
          Swal.fire(
            "",
            "Existen problemas en la red, no se pudo consultar su secuencial. Contáctese con el administrador del Sistema.",
            "error"
          ).then((response) => {
            if (response.isConfirmed) {
              redirectHome();
            }
          });
        }
      });
  }, [redirectHome, tokenUser]);

  const handleChange = (event) => {
    switch (event.target.name) {
      case "rise":
        const error = validations.rise(event.target.value);
        changeErrors({rise: error})
        if(error === null){
          addRise(event.target.value);
        }
        break;
      case "fechaEmision":
        changeErrors({fechaEmision: null})
        changeDateEmision(event.target.value);
        break;
      case "fechaAutorizacion":
        changeErrors({fechaDocSustento: null})
        changeDateAuth(event.target.value);
        break;
      case "numDoc":
        changeErrors({numeroDocSustento: null})
        changeNumDoc(event.target.value);
        break;
      case "typeDoc":
          changeTypeDoc(event.target.value);
          break;
      default:
        break;
    }
  };

  return (
    <Grid container className={classesView.containerDebitNote} id="debit-note">
      <Grid container xs={12} justify="center">
        <CardHeader
          className={classesGlobales.cabecera}
          title={"Datos Nota de Débito"}
          avatar={<AssignmentIcon />}
        />
      </Grid>
      <Grid container item xs={12} className={classesGlobales.containerForm}>
        <Grid item xs={12} md={2}>
          {" "}
          Numeración:
        </Grid>

        <Grid item xs={12} md={4}>
          {secuencialComp ? (
            <input
              className="form-control is-valid"
              value={secuencialComp}
              disabled
            />
          ) : (
            <CircularProgress size="20px" color="primary" />
          )}
        </Grid>
        <Grid item xs={12}>
          <hr />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ReferenceDocument
          type="DT"
          State={debitNote}
          onChange={handleChange}
          errorsRedux={debitNote.errors}
        />
      </Grid>
    </Grid>
  );
};

export default DataDebitNote;
