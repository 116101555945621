import actions from "../types/debitNoteType";
import { validationEmail } from "utils/checkInputs";

const initialState = {
  codigoImpuestoIce: null,
  codigoImpuestoIva: null,
  fechaDocSustento: null,
  fechaEmision: new Date(),
  idAsigancionEmpresaUsuario: null,
  idAsignacionEmpresaCliente: null,
  infromacionAdicional: [],
  motivos: [],
  numeroDocSustento: null,
  rise: null,
  rucEmpresa: null,
  tipoDocSustento: "01",
  formCostumer: {},
  errors: {
    codigoImpuestoIce: null,
    codigoImpuestoIva: null,
    fechaDocSustento: null,
    fechaEmision: null,
    motivos: null,
    numeroDocSustento: null,
    rise: null,
    idAsignacionEmpresaCliente: null,
  },
};
const removeEmailCostumer = (moreInfo = [], correo = null) => {
  let newMoreInfo = moreInfo.filter((item) => item.key !== "-1");
  if (typeof correo === "string") {
    if (validationEmail(correo)) {
      newMoreInfo.push({
        isEditable: false,
        nombre: "CORREO",
        valor: correo,
        isDeletable: false,
        key: "-1",
      });
    }
  }
  return newMoreInfo;
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.ADD_USER:
      return { ...state, idAsigancionEmpresaUsuario: action.payload };
    case actions.ADD_RUC:
      return { ...state, rucEmpresa: action.payload };
    case actions.ADD_RISE:
      return { ...state, rise: action.payload };
    case actions.ADD_REASONS:
      return { ...state, motivos: action.payload };
    case actions.ADD_COSTUMER:
      return { ...state, idAsignacionEmpresaCliente: action.payload };
    case actions.SAVE_CACHE:
      return {
        ...state,
        formCostumer: action.payload,
        infromacionAdicional: [
          ...removeEmailCostumer(
            state.infromacionAdicional,
            action.payload.correo
          ),
        ],
      };
    case actions.ADD_CODE_TAX_ICE:
      return { ...state, codigoImpuestoIce: action.payload };
    case actions.ADD_CODE_TAX_IVA:
      return { ...state, codigoImpuestoIva: action.payload };
    case actions.CHANGE_DATE_EMISION:
      return { ...state, fechaEmision: action.payload };
    case actions.CHANGE_DATE_AUTH:
      return { ...state, fechaDocSustento: action.payload };
    case actions.ADD_MORE_INFO:
      return { ...state, infromacionAdicional: action.payload };
    case actions.CHANGE_NUM_DOC:
      return { ...state, numeroDocSustento: action.payload };
    case actions.CHANGE_TYPE_DOC:
      return { ...state, tipoDocSustento: action.payload };
    case actions.CHANGE_ERRORS_LABELS:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case actions.CLEAR_ALL:
      return initialState;
    default:
      return state;
  }
}
