export const tipoRegiType = [
  { CODE: "01", DESCRIPTION: "Régimen general" },
  { CODE: "02", DESCRIPTION: "Paraíso fiscal" },
  {
    CODE: "03",
    DESCRIPTION: "Régimen fiscal preferente o jurisdicción de menor imposición",
  },
];

export const tipoRegi01Type = [
  { CODE: 16, DESCRIPTION: "AMERICAN SAMOA" },
  { CODE: 74, DESCRIPTION: "BOUVET ISLAND" },
  { CODE: 101, DESCRIPTION: "ARGENTINA" },
  { CODE: 102, DESCRIPTION: "BOLIVIA" },
  { CODE: 103, DESCRIPTION: "BRASIL" },
  { CODE: 104, DESCRIPTION: "CANADÁ" },
  { CODE: 334, DESCRIPTION: "QATAR" },
  { CODE: 335, DESCRIPTION: "MALDIVAS" },
  { CODE: 336, DESCRIPTION: "NEPAL" },
  { CODE: 337, DESCRIPTION: "OMAN" },
  { CODE: 338, DESCRIPTION: "SINGAPUR" },
  { CODE: 339, DESCRIPTION: "SRI LANKA (CEILAN)" },
  { CODE: 105, DESCRIPTION: "COLOMBIA" },
  { CODE: 106, DESCRIPTION: "COSTA RICA" },
  { CODE: 107, DESCRIPTION: "CUBA" },
  { CODE: 108, DESCRIPTION: "CHILE" },
  { CODE: 109, DESCRIPTION: "ANGUILA" },
  { CODE: 110, DESCRIPTION: "ESTADOS UNIDOS" },
  { CODE: 111, DESCRIPTION: "GUATEMALA" },
  { CODE: 112, DESCRIPTION: "HAITÍ" },
  { CODE: 113, DESCRIPTION: "HONDURAS" },
  { CODE: 114, DESCRIPTION: "JAMAICA" },
  { CODE: 115, DESCRIPTION: "MALVINAS ISLAS" },
  { CODE: 116, DESCRIPTION: "MÉXICO" },
  { CODE: 117, DESCRIPTION: "NICARAGUA" },
  { CODE: 118, DESCRIPTION: "PANAMÁ" },
  { CODE: 119, DESCRIPTION: "PARAGUAY" },
  { CODE: 120, DESCRIPTION: "PERÚ" },
  { CODE: 121, DESCRIPTION: "PUERTO RICO" },
  { CODE: 122, DESCRIPTION: "REPÚBLICA DOMINICANA" },
  { CODE: 123, DESCRIPTION: "EL SALVADOR" },
  { CODE: 124, DESCRIPTION: "TRINIDAD Y TOBAGO" },
  { CODE: 125, DESCRIPTION: "URUGUAY" },
  { CODE: 126, DESCRIPTION: "VENEZUELA" },
  { CODE: 127, DESCRIPTION: "CURAZAO" },
  { CODE: 129, DESCRIPTION: "BAHAMAS" },
  { CODE: 130, DESCRIPTION: "BARBADOS" },
  { CODE: 131, DESCRIPTION: "GRANADA" },
  { CODE: 132, DESCRIPTION: "GUYANA" },
  { CODE: 133, DESCRIPTION: "SURINAM" },
  { CODE: 134, DESCRIPTION: "ANTIGUA Y BARBUDA" },
  { CODE: 135, DESCRIPTION: "BELICE" },
  { CODE: 136, DESCRIPTION: "DOMINICA" },
  { CODE: 137, DESCRIPTION: "SAN CRISTOBAL Y NEVIS" },
  { CODE: 138, DESCRIPTION: "SANTA LUCÍA" },
  { CODE: 139, DESCRIPTION: "SAN VICENTE Y LAS GRANAD." },
  { CODE: 140, DESCRIPTION: "ANTILLAS HOLANDESAS" },
  { CODE: 141, DESCRIPTION: "ARUBA" },
  { CODE: 142, DESCRIPTION: "BERMUDA" },
  { CODE: 143, DESCRIPTION: "GUADALUPE" },
  { CODE: 144, DESCRIPTION: "GUYANA FRANCESA" },
  { CODE: 145, DESCRIPTION: "ISLAS CAIMÁN" },
  { CODE: 146, DESCRIPTION: "ISLAS VIRGENES (BRITANICAS)" },
  { CODE: 147, DESCRIPTION: "JOHNSTON ISLA" },
  { CODE: 148, DESCRIPTION: "MARTINICA" },
  { CODE: 149, DESCRIPTION: "MONTSERRAT ISLA" },
  { CODE: 151, DESCRIPTION: "TURCAS Y CAICOS ISLAS" },
  { CODE: 152, DESCRIPTION: "VIRGENES, ISLAS (NORT.AMER.)" },
  { CODE: 201, DESCRIPTION: "ALBANIA" },
  { CODE: 202, DESCRIPTION: "ALEMANIA" },
  { CODE: 203, DESCRIPTION: "AUSTRIA" },
  { CODE: 204, DESCRIPTION: "BÉLGICA" },
  { CODE: 205, DESCRIPTION: "BULGARIA" },
  { CODE: 207, DESCRIPTION: "ALBORAN Y PEREJIL" },
  { CODE: 208, DESCRIPTION: "DINAMARCA" },
  { CODE: 209, DESCRIPTION: "ESPAÑA" },
  { CODE: 211, DESCRIPTION: "FRANCIA" },
  { CODE: 212, DESCRIPTION: "FINLANDIA" },
  { CODE: 213, DESCRIPTION: "REINO UNIDO" },
  { CODE: 214, DESCRIPTION: "GRECIA" },
  { CODE: 341, DESCRIPTION: "VIETNAM" },
  { CODE: 342, DESCRIPTION: "YEMEN" },
  { CODE: 343, DESCRIPTION: "ISLAS HEARD Y MCDONALD" },
  { CODE: 344, DESCRIPTION: "BRUNEI DARUSSALAM" },
  { CODE: 346, DESCRIPTION: "TURQUÍA" },
  { CODE: 347, DESCRIPTION: "AZERBAIJÁN" },
  { CODE: 348, DESCRIPTION: "KAZAJSTÁN" },
  { CODE: 349, DESCRIPTION: "KIRGUIZISTÁN" },
  { CODE: 350, DESCRIPTION: "TAJIKISTAN" },
  { CODE: 351, DESCRIPTION: "TURKMENISTÁN" },
  { CODE: 352, DESCRIPTION: "UZBEKISTÁN" },
  { CODE: 353, DESCRIPTION: "PALESTINA" },
  { CODE: 354, DESCRIPTION: "HONG KONG" },
  { CODE: 355, DESCRIPTION: "MACAO" },
  { CODE: 356, DESCRIPTION: "ARMENIA" },
  { CODE: 382, DESCRIPTION: "MONTENEGRO" },
  { CODE: 402, DESCRIPTION: "BURKINA FASO" },
  { CODE: 403, DESCRIPTION: "ARGELIA" },
  { CODE: 404, DESCRIPTION: "BURUNDÍ" },
  { CODE: 405, DESCRIPTION: "CAMERÚN" },
  { CODE: 406, DESCRIPTION: "CONGO" },
  { CODE: 407, DESCRIPTION: "ETIOPÍA" },
  { CODE: 408, DESCRIPTION: "GAMBIA" },
  { CODE: 409, DESCRIPTION: "GUINEA" },
  { CODE: 410, DESCRIPTION: "LIBERIA" },
  { CODE: 412, DESCRIPTION: "MADAGASCAR" },
  { CODE: 413, DESCRIPTION: "MALAWI" },
  { CODE: 414, DESCRIPTION: "MALÍ" },
  { CODE: 415, DESCRIPTION: "MARRUECOS" },
  { CODE: 416, DESCRIPTION: "MAURITANIA" },
  { CODE: 417, DESCRIPTION: "NIGERIA" },
  { CODE: 419, DESCRIPTION: "ZIMBABWE (RHODESIA)" },
  { CODE: 420, DESCRIPTION: "SENEGAL" },
  { CODE: 421, DESCRIPTION: "SUDÁN" },
  { CODE: 422, DESCRIPTION: "SUDAFRICA (CISKEI)" },
  { CODE: 423, DESCRIPTION: "SIERRA LEONA" },
  { CODE: 425, DESCRIPTION: "TANZANIA" },
  { CODE: 426, DESCRIPTION: "UGANDA" },
  { CODE: 427, DESCRIPTION: "ZAMBIA" },
  { CODE: 428, DESCRIPTION: "ÅLAND ISLANDS" },
  { CODE: 429, DESCRIPTION: "BENIN" },
  { CODE: 430, DESCRIPTION: "BOTSWANA" },
  { CODE: 431, DESCRIPTION: "REPUBLICA CENTROAFRICANA" },
  { CODE: 432, DESCRIPTION: "COSTA DE MARFIL" },
  { CODE: 433, DESCRIPTION: "CHAD" },
  { CODE: 434, DESCRIPTION: "EGIPTO" },
  { CODE: 435, DESCRIPTION: "GABON" },
  { CODE: 436, DESCRIPTION: "GHANA" },
  { CODE: 437, DESCRIPTION: "GUINEA-BISSAU" },
  { CODE: 438, DESCRIPTION: "GUINEA ECUATORIAL" },
  { CODE: 439, DESCRIPTION: "KENIA" },
  { CODE: 440, DESCRIPTION: "LESOTHO" },
  { CODE: 441, DESCRIPTION: "MAURICIO" },
  { CODE: 442, DESCRIPTION: "MOZAMBIQUE" },
  { CODE: 443, DESCRIPTION: "MAYOTTE" },
  { CODE: 444, DESCRIPTION: "NIGER" },
  { CODE: 445, DESCRIPTION: "RWANDA" },
  { CODE: 446, DESCRIPTION: "SEYCHELLES" },
  { CODE: 215, DESCRIPTION: "PAISES BAJOS (HOLANDA)" },
  { CODE: 216, DESCRIPTION: "HUNGRÍA" },
  { CODE: 217, DESCRIPTION: "IRLANDA" },
  { CODE: 218, DESCRIPTION: "ISLANDIA" },
  { CODE: 219, DESCRIPTION: "ITALIA" },
  { CODE: 220, DESCRIPTION: "LUXEMBURGO" },
  { CODE: 221, DESCRIPTION: "MALTA" },
  { CODE: 222, DESCRIPTION: "NORUEGA" },
  { CODE: 223, DESCRIPTION: "POLONIA" },
  { CODE: 224, DESCRIPTION: "PORTUGAL" },
  { CODE: 225, DESCRIPTION: "RUMANIA" },
  { CODE: 226, DESCRIPTION: "SUECIA" },
  { CODE: 227, DESCRIPTION: "SUIZA" },
  { CODE: 228, DESCRIPTION: "CANARIAS ISLAS" },
  { CODE: 229, DESCRIPTION: "UCRANIA" },
  { CODE: 230, DESCRIPTION: "RUSIA" },
  { CODE: 231, DESCRIPTION: "YUGOSLAVIA" },
  { CODE: 233, DESCRIPTION: "ANDORRA" },
  { CODE: 234, DESCRIPTION: "LIECHTENSTEIN" },
  { CODE: 235, DESCRIPTION: "MÓNACO" },
  { CODE: 237, DESCRIPTION: "SAN MARINO" },
  { CODE: 238, DESCRIPTION: "VATICANO (SANTA SEDE)" },
  { CODE: 239, DESCRIPTION: "GIBRALTAR" },
  { CODE: 241, DESCRIPTION: "BELARUS" },
  { CODE: 242, DESCRIPTION: "BOSNIA Y HERZEGOVINA" },
  { CODE: 243, DESCRIPTION: "CROACIA" },
  { CODE: 244, DESCRIPTION: "ESLOVENIA" },
  { CODE: 245, DESCRIPTION: "ESTONIA" },
  { CODE: 246, DESCRIPTION: "GEORGIA" },
  { CODE: 247, DESCRIPTION: "GROENLANDIA" },
  { CODE: 248, DESCRIPTION: "LETONIA" },
  { CODE: 249, DESCRIPTION: "LITUANIA" },
  { CODE: 250, DESCRIPTION: "MOLDOVA" },
  { CODE: 251, DESCRIPTION: "MACEDONIA" },
  { CODE: 252, DESCRIPTION: "ESLOVAQUIA" },
  { CODE: 253, DESCRIPTION: "ISLAS FAROE" },
  { CODE: 260, DESCRIPTION: "FRENCH SOUTHERN TERRITORIES" },
  { CODE: 301, DESCRIPTION: "AFGANISTAN" },
  { CODE: 302, DESCRIPTION: "ARABIA SAUDITA" },
  { CODE: 303, DESCRIPTION: "MYANMAR (BURMA)" },
  { CODE: 304, DESCRIPTION: "CAMBOYA" },
  { CODE: 306, DESCRIPTION: "COREA NORTE" },
  { CODE: 307, DESCRIPTION: "TAIWAN (CHINA)" },
  { CODE: 308, DESCRIPTION: "FILIPINAS" },
  { CODE: 309, DESCRIPTION: "INDIA" },
  { CODE: 310, DESCRIPTION: "INDONESIA" },
  { CODE: 311, DESCRIPTION: "IRAK" },
  { CODE: 312, DESCRIPTION: "IRÁN (REPÚBLICA ISLÁMICA)" },
  { CODE: 313, DESCRIPTION: "ISRAEL" },
  { CODE: 314, DESCRIPTION: "JAPÓN" },
  { CODE: 315, DESCRIPTION: "JORDANIA" },
  { CODE: 316, DESCRIPTION: "KUWAIT" },
  { CODE: 317, DESCRIPTION: "LAOS, REP. POP. DEMOC." },
  { CODE: 318, DESCRIPTION: "LIBANO" },
  { CODE: 319, DESCRIPTION: "MALASIA" },
  { CODE: 321, DESCRIPTION: "MONGOLIA (MANCHURIA)" },
  { CODE: 322, DESCRIPTION: "PAKISTÁN" },
  { CODE: 323, DESCRIPTION: "SIRIA" },
  { CODE: 447, DESCRIPTION: "SAHARA OCCIDENTAL" },
  { CODE: 448, DESCRIPTION: "SOMALIA" },
  { CODE: 449, DESCRIPTION: "SANTO TOME Y PRINCIPE" },
  { CODE: 450, DESCRIPTION: "SWAZILANDIA" },
  { CODE: 451, DESCRIPTION: "TOGO" },
  { CODE: 452, DESCRIPTION: "TUNEZ" },
  { CODE: 453, DESCRIPTION: "ZAIRE" },
  { CODE: 454, DESCRIPTION: "ANGOLA" },
  { CODE: 456, DESCRIPTION: "CABO VERDE" },
  { CODE: 458, DESCRIPTION: "COMORAS" },
  { CODE: 459, DESCRIPTION: "DJIBOUTI" },
  { CODE: 460, DESCRIPTION: "NAMIBIA" },
  { CODE: 463, DESCRIPTION: "ERITREA" },
  { CODE: 464, DESCRIPTION: "MOROCCO" },
  { CODE: 465, DESCRIPTION: "REUNION" },
  { CODE: 466, DESCRIPTION: "SANTA ELENA" },
  { CODE: 499, DESCRIPTION: "JERSEY" },
  { CODE: 501, DESCRIPTION: "AUSTRALIA" },
  { CODE: 503, DESCRIPTION: "NUEVA ZELANDA" },
  { CODE: 504, DESCRIPTION: "SAMOA OCCIDENTAL" },
  { CODE: 506, DESCRIPTION: "FIJI" },
  { CODE: 507, DESCRIPTION: "PAPUA NUEVA GUINEA" },
  { CODE: 508, DESCRIPTION: "TONGA" },
  { CODE: 509, DESCRIPTION: "PALAO (BELAU) ISLAS" },
  { CODE: 510, DESCRIPTION: "KIRIBATI" },
  { CODE: 511, DESCRIPTION: "MARSHALL ISLAS" },
  { CODE: 512, DESCRIPTION: "MICRONESIA" },
  { CODE: 513, DESCRIPTION: "NAURU" },
  { CODE: 514, DESCRIPTION: "SALOMON ISLAS" },
  { CODE: 515, DESCRIPTION: "TUVALU" },
  { CODE: 516, DESCRIPTION: "VANUATU" },
  { CODE: 517, DESCRIPTION: "GUAM" },
  { CODE: 518, DESCRIPTION: "ISLAS COCOS (KEELING)" },
  { CODE: 519, DESCRIPTION: "ISLAS COOK" },
  { CODE: 520, DESCRIPTION: "ISLAS NAVIDAD" },
  { CODE: 521, DESCRIPTION: "MIDWAY ISLAS" },
  { CODE: 522, DESCRIPTION: "NIUE ISLA" },
  { CODE: 523, DESCRIPTION: "NORFOLK ISLA" },
  { CODE: 524, DESCRIPTION: "NUEVA CALEDONIA" },
  { CODE: 525, DESCRIPTION: "PITCAIRN, ISLA" },
  { CODE: 526, DESCRIPTION: "POLINESIA FRANCESA" },
  { CODE: 529, DESCRIPTION: "TIMOR DEL ESTE" },
  { CODE: 530, DESCRIPTION: "TOKELAI" },
  { CODE: 531, DESCRIPTION: "WAKE ISLA" },
  { CODE: 532, DESCRIPTION: "WALLIS Y FUTUNA, ISLAS" },
  { CODE: 590, DESCRIPTION: "SAINT BARTHELEMY" },
  { CODE: 593, DESCRIPTION: "ECUADOR" },
  { CODE: 594, DESCRIPTION: "AGUAS INTERNACIONALES" },
  { CODE: 595, DESCRIPTION: "ALTO VOLTA" },
  { CODE: 596, DESCRIPTION: "BIELORRUSIA" },
  { CODE: 597, DESCRIPTION: "COTE DÍVOIRE" },
  { CODE: 598, DESCRIPTION: "CYPRUS" },
  { CODE: 599, DESCRIPTION: "REPÚBLICA CHECA" },
  { CODE: 600, DESCRIPTION: "FALKLAND ISLANDS" },
  { CODE: 601, DESCRIPTION: "LATVIA" },
  { CODE: 602, DESCRIPTION: "LIBIA" },
  { CODE: 603, DESCRIPTION: "NORTHERN MARIANA ISL" },
  { CODE: 604, DESCRIPTION: "ST. PIERRE AND MIQUE" },
  { CODE: 325, DESCRIPTION: "TAILANDIA" },
  { CODE: 327, DESCRIPTION: "BAHREIN" },
  { CODE: 328, DESCRIPTION: "BANGLADESH" },
  { CODE: 329, DESCRIPTION: "BUTÁN" },
  { CODE: 330, DESCRIPTION: "COREA DEL SUR" },
  { CODE: 331, DESCRIPTION: "CHINA POPULAR" },
  { CODE: 332, DESCRIPTION: "CHIPRE" },
  { CODE: 333, DESCRIPTION: "EMIRATOS ARABES UNIDOS" },
  { CODE: 605, DESCRIPTION: "SYRIAN ARAB REPUBLIC" },
  { CODE: 606, DESCRIPTION: "TERRITORIO ANTÁRTICO BRITÁNICO" },
  { CODE: 607, DESCRIPTION: "TERRITORIO BRITÁNICO OCÉANO IN" },
  { CODE: 688, DESCRIPTION: "SERBIA" },
  { CODE: 831, DESCRIPTION: "GUERNSEY" },
  { CODE: 832, DESCRIPTION: "JERSEY" },
  { CODE: 833, DESCRIPTION: "ISLE OF MAN" },
];

export const tipoRegi02Type = [
  {
    CODE: 109,
    DESCRIPTION: "ANGUILA (Territorio no autónomo del Reino Unido)",
  },
  { CODE: 134, DESCRIPTION: "ANTIGUA Y BARBUDA (Estado independiente)" },
  { CODE: 222, DESCRIPTION: "ARCHIPIÉLAGO DE SVALBARD" },
  { CODE: 141, DESCRIPTION: "ARUBA" },
  { CODE: 130, DESCRIPTION: "BARBADOS (Estado independiente)" },
  { CODE: 135, DESCRIPTION: "BELICE (Estado independiente)" },
  {
    CODE: 142,
    DESCRIPTION: "BERMUDAS (Territorio no autónomo del Reino Unido)",
  },
  { CODE: 215, DESCRIPTION: "BONAIRE, SABA Y SAN EUSTAQUIO" },
  { CODE: 344, DESCRIPTION: "BRUNEI DARUSSALAM (Estado independiente)" },
  { CODE: 219, DESCRIPTION: "CAMPIONE D'ITALIA (Comune di Campioned'Italia)" },
  { CODE: 239, DESCRIPTION: "COLONIA DE GIBRALTAR" },
  { CODE: 129, DESCRIPTION: "COMUNIDAD DE LAS BAHAMAS (Estado independiente)" },
  { CODE: 127, DESCRIPTION: "CURAZAO" },
  {
    CODE: 131,
    DESCRIPTION: "ESTADO ASOCIADO DE GRANADA (Estado independiente)",
  },
  { CODE: 327, DESCRIPTION: "ESTADO DE BAHREIN (Estado independiente)" },
  { CODE: 316, DESCRIPTION: "ESTADO DE KUWAIT (Estado independiente)" },
  {
    CODE: 121,
    DESCRIPTION:
      "ESTADO LIBRE ASOCIADO DE PUERTO RICO (Estado asociado a los EEUU)",
  },
  { CODE: 333, DESCRIPTION: "EMIRATOS ÁRABES UNIDOS (Estado independiente)" },
  {
    CODE: 137,
    DESCRIPTION:
      "FEDERACIÓN DE SAN CRISTÓBAL (Islas Saint Kitts and Nevis: independientes)",
  },
  { CODE: 220, DESCRIPTION: "GRAN DUCADO DE LUXEMBURGO" },
  { CODE: 247, DESCRIPTION: "GROENLANDIA" },
  { CODE: 517, DESCRIPTION: "GUAM (Territorio no autónomo de los EEUU)" },
  { CODE: 466, DESCRIPTION: "ISLA DE ASCENSIÓN" },
  { CODE: 224, DESCRIPTION: "ISLAS AZORES" },
  {
    CODE: 145,
    DESCRIPTION: "ISLAS CAIMÁN (Territorio no autónomo del Reino Unido)",
  },
  { CODE: 520, DESCRIPTION: "ISLAS CHRISTMAS" },
  { CODE: 518, DESCRIPTION: "ISLA DE COCOS O KEELING" },
  {
    CODE: 519,
    DESCRIPTION: "ISLA DE COOK (Territorio autónomo asociado a Nueva Zelanda)",
  },
  { CODE: 833, DESCRIPTION: "ISLA DE MAN (Territorio del Reino Unido)" },
  { CODE: 523, DESCRIPTION: "ISLA DE NORFOLK" },
  { CODE: 604, DESCRIPTION: "ISLA DE SAN PEDRO Y MIGUELÓN" },
  {
    CODE: 831,
    DESCRIPTION:
      "ISLAS DEL CANAL (Guernesey, Alderney, Isla de Great Stark, Herm, Little Sark, Brechou, Jethou, Lihou)",
  },
  { CODE: 312, DESCRIPTION: "ISLA QESHM" },
  { CODE: 514, DESCRIPTION: "ISLAS SALOMÓN" },
  {
    CODE: 151,
    DESCRIPTION:
      "ISLAS TURKAS E ISLAS CAICOS (Territorio no autónomo del Reino Unido)",
  },
  {
    CODE: 146,
    DESCRIPTION:
      "ISLAS VÍRGENES BRITÁNICAS (Territorio no autónomo del Reino Unido)",
  },
  { CODE: 152, DESCRIPTION: "ISLAS VÍRGENES DE ESTADOS UNIDOS DE AMÉRICA" },
  { CODE: 510, DESCRIPTION: "KIRIBATI" },
  { CODE: 319, DESCRIPTION: "LABUAN" },
  { CODE: 355, DESCRIPTION: "MACAO" },
  { CODE: 224, DESCRIPTION: "MADEIRA (Territorio de Portugal)" },
  { CODE: 136, DESCRIPTION: "MANCOMUNIDAD DE DOMINICA (Estado asociado)" },
  {
    CODE: 149,
    DESCRIPTION: "MONTSERRAT (Territorio no autónomo del Reino Unido)",
  },
  { CODE: 303, DESCRIPTION: "MYANMAR (ex Birmania)" },
  { CODE: 417, DESCRIPTION: "NIGERIA" },
  { CODE: 522, DESCRIPTION: "NIUE" },
  { CODE: 509, DESCRIPTION: "PALAU" },
  { CODE: 525, DESCRIPTION: "PITCAIRN" },
  {
    CODE: 260,
    DESCRIPTION: "POLINESIA FRANCESA (Territorio de Ultramar de Francia)",
  },
  {
    CODE: 234,
    DESCRIPTION: "PRINCIPADO DE LIECHTENSTEIN (Estado independiente)",
  },
  { CODE: 235, DESCRIPTION: "PRINCIPADO DE MÓNACO" },
  { CODE: 233, DESCRIPTION: "PRINCIPADO DEL VALLE DE ANDORRA" },
  { CODE: 450, DESCRIPTION: "REINO DE SWAZILANDIA (Estado independiente)" },
  { CODE: 508, DESCRIPTION: "REINO DE TONGA (Estado independiente)" },
  { CODE: 315, DESCRIPTION: "REINO HACHEMITA DE JORDANIA" },
  {
    CODE: 132,
    DESCRIPTION: "REPÚBLICA COOPERATIVA DE GUYANA (Estado independiente)",
  },
  { CODE: 201, DESCRIPTION: "REPÚBLICA DE ALBANIA" },
  { CODE: 454, DESCRIPTION: "REPÚBLICA DE ANGOLA" },
  { CODE: 456, DESCRIPTION: "REPÚBLICA DE CABO VERDE (Estado independiente)" },
  { CODE: 332, DESCRIPTION: "REPÚBLICA DE CHIPRE" },
  { CODE: 459, DESCRIPTION: "REPÚBLICA DE DJIBOUTI (Estado independiente)" },
  {
    CODE: 511,
    DESCRIPTION: "REPÚBLICA DE LAS ISLAS MARSHALL (Estado independiente)",
  },
  { CODE: 410, DESCRIPTION: "REPÚBLICA DE LIBERIA (Estado independiente)" },
  { CODE: 335, DESCRIPTION: "REPÚBLICA DE MALDIVAS (Estado independiente)" },
  { CODE: 221, DESCRIPTION: "REPÚBLICA DE MALTA (Estado independiente)" },
  { CODE: 441, DESCRIPTION: "REPÚBLICA DE MAURICIO" },
  { CODE: 513, DESCRIPTION: "REPÚBLICA DE NAURU (Estado independiente)" },
  { CODE: 118, DESCRIPTION: "REPÚBLICA DE PANAMÁ (Estado independiente)" },
  { CODE: 446, DESCRIPTION: "REPÚBLICA DE SEYCHELLES (Estado independiente)" },
  { CODE: 124, DESCRIPTION: "REPÚBLICA DE TRINIDAD Y TOBAGO" },
  { CODE: 452, DESCRIPTION: "REPÚBLICA DE TÚNEZ" },
  { CODE: 516, DESCRIPTION: "REPÚBLICA DE VANUATU" },
  { CODE: 342, DESCRIPTION: "REPÚBLICA DEL YEMEN" },
  { CODE: 339, DESCRIPTION: "REPÚBLICA DEMOCRÁTICA SOCIALISTA DE SRI LANKA" },
  {
    CODE: 16,
    DESCRIPTION: "SAMOA AMERICANA (Territorio no autónomo de los EEUU)",
  },
  { CODE: 504, DESCRIPTION: "SAMOA OCCIDENTAL" },
  {
    CODE: 139,
    DESCRIPTION: "SAN VICENTE Y LAS GRANADINAS (Estado independiente)",
  },
  { CODE: 466, DESCRIPTION: "SANTA ELENA" },
  { CODE: 138, DESCRIPTION: "SANTA LUCÍA" },
  { CODE: 534, DESCRIPTION: "SAN MARTÍN" },
  {
    CODE: 237,
    DESCRIPTION: "SERENÍSIMA REPÚBLICA DE SAN MARINO (Estado independiente)",
  },
  { CODE: 337, DESCRIPTION: "SULTANADO DE OMAN" },
  { CODE: 530, DESCRIPTION: "TOKELAU" },
  { CODE: 466, DESCRIPTION: "TRISTAN DA CUNHA (SH Saint Helena)" },
  { CODE: 515, DESCRIPTION: "TUVALU" },
  { CODE: 599, DESCRIPTION: "ZONA LIBRE DE OSTRAVA" },
  { CODE: 499, DESCRIPTION: "ISLAS DEL CANAL (Jersey)" },
  { CODE: 354, DESCRIPTION: "HONG KONG" },
];

export const tipoRegi03Type = [
  { CODE: 16, DESCRIPTION: "AMERICAN SAMOA" },
  { CODE: 74, DESCRIPTION: "BOUVET ISLAND" },
  { CODE: 101, DESCRIPTION: "ARGENTINA" },
  { CODE: 102, DESCRIPTION: "BOLIVIA" },
  { CODE: 103, DESCRIPTION: "BRASIL" },
  { CODE: 104, DESCRIPTION: "CANADA" },
  { CODE: 105, DESCRIPTION: "COLOMBIA" },
  { CODE: 106, DESCRIPTION: "COSTA RICA" },
  { CODE: 107, DESCRIPTION: "CUBA" },
  { CODE: 108, DESCRIPTION: "CHILE" },
  { CODE: 109, DESCRIPTION: "ANGUILA" },
  { CODE: 110, DESCRIPTION: "ESTADOS UNIDOS" },
  { CODE: 111, DESCRIPTION: "GUATEMALA" },
  { CODE: 112, DESCRIPTION: "HAITI" },
  { CODE: 113, DESCRIPTION: "HONDURAS" },
  { CODE: 114, DESCRIPTION: "JAMAICA" },
  { CODE: 115, DESCRIPTION: "MALVINAS  ISLAS" },
  { CODE: 116, DESCRIPTION: "MEXICO" },
  { CODE: 117, DESCRIPTION: "NICARAGUA" },
  { CODE: 118, DESCRIPTION: "PANAMA" },
  { CODE: 119, DESCRIPTION: "PARAGUAY" },
  { CODE: 120, DESCRIPTION: "PERU" },
  { CODE: 121, DESCRIPTION: "PUERTO RICO" },
  { CODE: 122, DESCRIPTION: "REPUBLICA DOMINICANA" },
  { CODE: 123, DESCRIPTION: "EL SALVADOR" },
  { CODE: 124, DESCRIPTION: "TRINIDAD Y TOBAGO" },
  { CODE: 125, DESCRIPTION: "URUGUAY" },
  { CODE: 126, DESCRIPTION: "VENEZUELA" },
  { CODE: 127, DESCRIPTION: "CURAZAO" },
  { CODE: 129, DESCRIPTION: "BAHAMAS" },
  { CODE: 130, DESCRIPTION: "BARBADOS" },
  { CODE: 131, DESCRIPTION: "GRANADA" },
  { CODE: 132, DESCRIPTION: "GUYANA" },
  { CODE: 133, DESCRIPTION: "SURINAM" },
  { CODE: 134, DESCRIPTION: "ANTIGUA Y BARBUDA" },
  { CODE: 135, DESCRIPTION: "BELICE" },
  { CODE: 136, DESCRIPTION: "DOMINICA" },
  { CODE: 137, DESCRIPTION: "SAN CRISTOBAL Y NEVIS" },
  { CODE: 138, DESCRIPTION: "SANTA LUCIA" },
  { CODE: 139, DESCRIPTION: "SAN VICENTE Y LAS GRANAD." },
  { CODE: 141, DESCRIPTION: "ARUBA" },
  { CODE: 142, DESCRIPTION: "BERMUDA" },
  { CODE: 143, DESCRIPTION: "GUADALUPE" },
  { CODE: 144, DESCRIPTION: "GUYANA FRANCESA" },
  { CODE: 145, DESCRIPTION: "ISLAS CAIMAN" },
  { CODE: 146, DESCRIPTION: "ISLAS VIRGENES (BRITANICAS)" },
  { CODE: 147, DESCRIPTION: "JOHNSTON ISLA" },
  { CODE: 148, DESCRIPTION: "MARTINICA" },
  { CODE: 149, DESCRIPTION: "MONTSERRAT ISLA" },
  { CODE: 151, DESCRIPTION: "TURCAS  Y CAICOS ISLAS" },
  { CODE: 152, DESCRIPTION: "VIRGENES,ISLAS(NORT.AMER.)" },
  { CODE: 201, DESCRIPTION: "ALBANIA" },
  { CODE: 202, DESCRIPTION: "ALEMANIA" },
  { CODE: 203, DESCRIPTION: "AUSTRIA" },
  { CODE: 204, DESCRIPTION: "BELGICA" },
  { CODE: 205, DESCRIPTION: "BULGARIA" },
  { CODE: 207, DESCRIPTION: "ALBORAN Y PEREJIL" },
  { CODE: 208, DESCRIPTION: "DINAMARCA" },
  { CODE: 209, DESCRIPTION: "ESPAÑA" },
  { CODE: 211, DESCRIPTION: "FRANCIA" },
  { CODE: 212, DESCRIPTION: "FINLANDIA" },
  { CODE: 213, DESCRIPTION: "REINO UNIDO" },
  { CODE: 214, DESCRIPTION: "GRECIA" },
  { CODE: 215, DESCRIPTION: "PAISES BAJOS (HOLANDA)" },
  { CODE: 216, DESCRIPTION: "HUNGRIA" },
  { CODE: 217, DESCRIPTION: "IRLANDA" },
  { CODE: 218, DESCRIPTION: "ISLANDIA" },
  { CODE: 219, DESCRIPTION: "ITALIA" },
  { CODE: 220, DESCRIPTION: "LUXEMBURGO" },
  { CODE: 221, DESCRIPTION: "MALTA" },
  { CODE: 222, DESCRIPTION: "NORUEGA" },
  { CODE: 223, DESCRIPTION: "POLONIA" },
  { CODE: 224, DESCRIPTION: "PORTUGAL" },
  { CODE: 225, DESCRIPTION: "RUMANIA" },
  { CODE: 226, DESCRIPTION: "SUECIA" },
  { CODE: 227, DESCRIPTION: "SUIZA" },
  { CODE: 228, DESCRIPTION: "CANARIAS  ISLAS" },
  { CODE: 229, DESCRIPTION: "UCRANIA" },
  { CODE: 230, DESCRIPTION: "RUSIA" },
  { CODE: 231, DESCRIPTION: "YUGOSLAVIA" },
  { CODE: 233, DESCRIPTION: "ANDORRA" },
  { CODE: 234, DESCRIPTION: "LIECHTENSTEIN" },
  { CODE: 235, DESCRIPTION: "MONACO" },
  { CODE: 237, DESCRIPTION: "SAN MARINO" },
  { CODE: 238, DESCRIPTION: "VATICANO (SANTA SEDE)" },
  { CODE: 239, DESCRIPTION: "GIBRALTAR" },
  { CODE: 241, DESCRIPTION: "BELARUS" },
  { CODE: 242, DESCRIPTION: "BOSNIA Y HERZEGOVINA" },
  { CODE: 243, DESCRIPTION: "CROACIA" },
  { CODE: 244, DESCRIPTION: "ESLOVENIA" },
  { CODE: 245, DESCRIPTION: "ESTONIA" },
  { CODE: 246, DESCRIPTION: "GEORGIA" },
  { CODE: 247, DESCRIPTION: "GROENLANDIA" },
  { CODE: 248, DESCRIPTION: "LETONIA" },
  { CODE: 249, DESCRIPTION: "LITUANIA" },
  { CODE: 250, DESCRIPTION: "MOLDOVA" },
  { CODE: 251, DESCRIPTION: "MACEDONIA" },
  { CODE: 252, DESCRIPTION: "ESLOVAQUIA" },
  { CODE: 253, DESCRIPTION: "ISLAS FAROE" },
  { CODE: 260, DESCRIPTION: "FRENCH SOUTHERN TERRITORIES" },
  { CODE: 301, DESCRIPTION: "AFGANISTAN" },
  { CODE: 302, DESCRIPTION: "ARABIA SAUDITA" },
  { CODE: 303, DESCRIPTION: "MYANMAR (BURMA)" },
  { CODE: 304, DESCRIPTION: "CAMBOYA" },
  { CODE: 306, DESCRIPTION: "COREA NORTE" },
  { CODE: 307, DESCRIPTION: "TAIWAN (CHINA)" },
  { CODE: 308, DESCRIPTION: "FILIPINAS" },
  { CODE: 309, DESCRIPTION: "INDIA" },
  { CODE: 310, DESCRIPTION: "INDONESIA" },
  { CODE: 311, DESCRIPTION: "IRAK" },
  { CODE: 312, DESCRIPTION: "IRAN (REPUBLICA ISLAMICA)" },
  { CODE: 313, DESCRIPTION: "ISRAEL" },
  { CODE: 314, DESCRIPTION: "JAPON" },
  { CODE: 315, DESCRIPTION: "JORDANIA" },
  { CODE: 316, DESCRIPTION: "KUWAIT" },
  { CODE: 317, DESCRIPTION: "LAOS, REP. POP. DEMOC." },
  { CODE: 318, DESCRIPTION: "LIBANO" },
  { CODE: 319, DESCRIPTION: "MALASIA" },
  { CODE: 321, DESCRIPTION: "MONGOLIA (MANCHURIA)" },
  { CODE: 322, DESCRIPTION: "PAKISTAN" },
  { CODE: 323, DESCRIPTION: "SIRIA" },
  { CODE: 325, DESCRIPTION: "TAILANDIA" },
  { CODE: 327, DESCRIPTION: "BAHREIN" },
  { CODE: 328, DESCRIPTION: "BANGLADESH" },
  { CODE: 329, DESCRIPTION: "BUTAN" },
  { CODE: 330, DESCRIPTION: "COREA DEL SUR" },
  { CODE: 331, DESCRIPTION: "CHINA POPULAR" },
  { CODE: 332, DESCRIPTION: "CHIPRE" },
  { CODE: 333, DESCRIPTION: "EMIRATOS ARABES UNIDOS" },
  { CODE: 334, DESCRIPTION: "QATAR" },
  { CODE: 335, DESCRIPTION: "MALDIVAS" },
  { CODE: 336, DESCRIPTION: "NEPAL" },
  { CODE: 337, DESCRIPTION: "OMAN" },
  { CODE: 338, DESCRIPTION: "SINGAPUR" },
  { CODE: 339, DESCRIPTION: "SRI LANKA (CEILAN)" },
  { CODE: 341, DESCRIPTION: "VIETNAM" },
  { CODE: 342, DESCRIPTION: "YEMEN" },
  { CODE: 343, DESCRIPTION: "ISLAS HEARD Y MCDONALD" },
  { CODE: 344, DESCRIPTION: "BRUNEI DARUSSALAM" },
  { CODE: 346, DESCRIPTION: "TURQUIA" },
  { CODE: 347, DESCRIPTION: "AZERBAIJAN" },
  { CODE: 348, DESCRIPTION: "KAZAJSTAN" },
  { CODE: 349, DESCRIPTION: "KIRGUIZISTAN" },
  { CODE: 350, DESCRIPTION: "TAJIKISTAN" },
  { CODE: 351, DESCRIPTION: "TURKMENISTAN" },
  { CODE: 352, DESCRIPTION: "UZBEKISTAN" },
  { CODE: 353, DESCRIPTION: "PALESTINA" },
  { CODE: 354, DESCRIPTION: "HONG KONG" },
  { CODE: 355, DESCRIPTION: "MACAO" },
  { CODE: 356, DESCRIPTION: "ARMENIA" },
  { CODE: 382, DESCRIPTION: "MONTENEGRO" },
  { CODE: 402, DESCRIPTION: "BURKINA FASO" },
  { CODE: 403, DESCRIPTION: "ARGELIA" },
  { CODE: 404, DESCRIPTION: "BURUNDI" },
  { CODE: 405, DESCRIPTION: "CAMERUN" },
  { CODE: 406, DESCRIPTION: "CONGO" },
  { CODE: 407, DESCRIPTION: "ETIOPIA" },
  { CODE: 408, DESCRIPTION: "GAMBIA" },
  { CODE: 409, DESCRIPTION: "GUINEA" },
  { CODE: 410, DESCRIPTION: "LIBERIA" },
  { CODE: 412, DESCRIPTION: "MADAGASCAR" },
  { CODE: 413, DESCRIPTION: "MALAWI" },
  { CODE: 414, DESCRIPTION: "MALI" },
  { CODE: 415, DESCRIPTION: "MARRUECOS" },
  { CODE: 416, DESCRIPTION: "MAURITANIA" },
  { CODE: 417, DESCRIPTION: "NIGERIA" },
  { CODE: 419, DESCRIPTION: "ZIMBABWE (RHODESIA)" },
  { CODE: 420, DESCRIPTION: "SENEGAL" },
  { CODE: 421, DESCRIPTION: "SUDAN" },
  { CODE: 422, DESCRIPTION: "SUDAFRICA  (CISKEI)" },
  { CODE: 423, DESCRIPTION: "SIERRA LEONA" },
  { CODE: 425, DESCRIPTION: "TANZANIA" },
  { CODE: 426, DESCRIPTION: "UGANDA" },
  { CODE: 427, DESCRIPTION: "ZAMBIA" },
  { CODE: 428, DESCRIPTION: "ÅLAND ISLANDS" },
  { CODE: 429, DESCRIPTION: "BENIN" },
  { CODE: 430, DESCRIPTION: "BOTSWANA" },
  { CODE: 431, DESCRIPTION: "REPUBLICA CENTROAFRICANA" },
  { CODE: 432, DESCRIPTION: "COSTA DE MARFIL" },
  { CODE: 433, DESCRIPTION: "CHAD" },
  { CODE: 434, DESCRIPTION: "EGIPTO" },
  { CODE: 435, DESCRIPTION: "GABON" },
  { CODE: 436, DESCRIPTION: "GHANA" },
  { CODE: 437, DESCRIPTION: "GUINEA-BISSAU" },
  { CODE: 438, DESCRIPTION: "GUINEA ECUATORIAL" },
  { CODE: 439, DESCRIPTION: "KENIA" },
  { CODE: 440, DESCRIPTION: "LESOTHO" },
  { CODE: 441, DESCRIPTION: "MAURICIO" },
  { CODE: 442, DESCRIPTION: "MOZAMBIQUE" },
  { CODE: 443, DESCRIPTION: "MAYOTTE" },
  { CODE: 444, DESCRIPTION: "NIGER" },
  { CODE: 445, DESCRIPTION: "RWANDA" },
  { CODE: 446, DESCRIPTION: "SEYCHELLES" },
  { CODE: 447, DESCRIPTION: "SAHARA OCCIDENTAL" },
  { CODE: 448, DESCRIPTION: "SOMALIA" },
  { CODE: 449, DESCRIPTION: "SANTO TOME Y PRINCIPE" },
  { CODE: 450, DESCRIPTION: "SWAZILANDIA" },
  { CODE: 451, DESCRIPTION: "TOGO" },
  { CODE: 452, DESCRIPTION: "TUNEZ" },
  { CODE: 453, DESCRIPTION: "ZAIRE" },
  { CODE: 454, DESCRIPTION: "ANGOLA" },
  { CODE: 456, DESCRIPTION: "CABO VERDE" },
  { CODE: 458, DESCRIPTION: "COMORAS" },
  { CODE: 459, DESCRIPTION: "DJIBOUTI" },
  { CODE: 460, DESCRIPTION: "NAMIBIA" },
  { CODE: 463, DESCRIPTION: "ERITREA" },
  { CODE: 464, DESCRIPTION: "MOROCCO" },
  { CODE: 465, DESCRIPTION: "REUNION" },
  { CODE: 466, DESCRIPTION: "SANTA ELENA" },
  { CODE: 499, DESCRIPTION: "JERSEY" },
  { CODE: 501, DESCRIPTION: "AUSTRALIA" },
  { CODE: 503, DESCRIPTION: "NUEVA ZELANDA" },
  { CODE: 504, DESCRIPTION: "SAMOA OCCIDENTAL" },
  { CODE: 506, DESCRIPTION: "FIJI" },
  { CODE: 507, DESCRIPTION: "PAPUA NUEVA GUINEA" },
  { CODE: 508, DESCRIPTION: "TONGA" },
  { CODE: 509, DESCRIPTION: "PALAO  (BELAU)  ISLAS" },
  { CODE: 510, DESCRIPTION: "KIRIBATI" },
  { CODE: 511, DESCRIPTION: "MARSHALL ISLAS" },
  { CODE: 512, DESCRIPTION: "MICRONESIA" },
  { CODE: 513, DESCRIPTION: "NAURU" },
  { CODE: 514, DESCRIPTION: "SALOMON  ISLAS" },
  { CODE: 515, DESCRIPTION: "TUVALU" },
  { CODE: 516, DESCRIPTION: "VANUATU" },
  { CODE: 517, DESCRIPTION: "GUAM" },
  { CODE: 518, DESCRIPTION: "ISLAS COCOS (KEELING)" },
  { CODE: 519, DESCRIPTION: "ISLAS COOK" },
  { CODE: 520, DESCRIPTION: "ISLAS NAVIDAD" },
  { CODE: 521, DESCRIPTION: "MIDWAY ISLAS" },
  { CODE: 522, DESCRIPTION: "NIUE ISLA" },
  { CODE: 523, DESCRIPTION: "NORFOLK ISLA" },
  { CODE: 524, DESCRIPTION: "NUEVA  CALEDONIA" },
  { CODE: 525, DESCRIPTION: "PITCAIRN, ISLA" },
  { CODE: 526, DESCRIPTION: "POLINESIA FRANCESA" },
  { CODE: 529, DESCRIPTION: "TIMOR DEL ESTE" },
  { CODE: 530, DESCRIPTION: "TOKELAI" },
  { CODE: 531, DESCRIPTION: "WAKE ISLA" },
  { CODE: 532, DESCRIPTION: "WALLIS Y FUTUNA, ISLAS" },
  { CODE: 534, DESCRIPTION: "SINT MAARTEN (DUTCH PART)" },
  { CODE: 590, DESCRIPTION: "SAINT BARTHELEMY" },
  { CODE: 593, DESCRIPTION: "ECUADOR" },
  { CODE: 594, DESCRIPTION: "AGUAS INTERNACIONALES" },
  { CODE: 595, DESCRIPTION: "ALTO VOLTA" },
  { CODE: 596, DESCRIPTION: "BIELORRUSIA" },
  { CODE: 597, DESCRIPTION: "COTE DÍVOIRE" },
  { CODE: 598, DESCRIPTION: "CYPRUS" },
  { CODE: 599, DESCRIPTION: "REPUBLICA CHECA" },
  { CODE: 600, DESCRIPTION: "FALKLAND ISLANDS" },
  { CODE: 601, DESCRIPTION: "LATVIA" },
  { CODE: 602, DESCRIPTION: "LIBIA" },
  { CODE: 603, DESCRIPTION: "NORTHERN MARIANA ISL" },
  { CODE: 604, DESCRIPTION: "ST. PIERRE AND MIQUE" },
  { CODE: 605, DESCRIPTION: "SYRIAN ARAB REPUBLIC" },
  { CODE: 606, DESCRIPTION: "TERRITORIO ANTARTICO BRITANICO" },
  { CODE: 607, DESCRIPTION: "TERRITORIO BRITANICO OCEANO IN" },
  { CODE: 688, DESCRIPTION: "SERBIA" },
  { CODE: 831, DESCRIPTION: "GUERNSEY" },
  { CODE: 833, DESCRIPTION: "ISLE OF MAN" },
];

export const reimbursementDocumentType = [
  { CODE: "01", DESCRIPTION: "Factura" },
  { CODE: "02", DESCRIPTION: "Nota o boleta de venta" },
  {
    CODE: "03",
    DESCRIPTION: "Liquidación de compra de Bienes o Prestación de servicios",
  },
  { CODE: "04", DESCRIPTION: "Nota de crédito" },
  { CODE: "05", DESCRIPTION: "Nota de débito" },
  { CODE: "06", DESCRIPTION: "Guías de Remisión " },
  { CODE: "07", DESCRIPTION: "Comprobante de Retención" },
  { CODE: "08", DESCRIPTION: "Boletos o entradas a espectáculos públicos" },
  {
    CODE: "09",
    DESCRIPTION: "Tiquetes o vales emitidos por máquinas registradoras",
  },
  { CODE: "11", DESCRIPTION: "Pasajes expedidos por empresas de aviación" },
  {
    CODE: "12",
    DESCRIPTION: "Documentos emitidos por instituciones financieras",
  },
  { CODE: "15", DESCRIPTION: "Comprobante de venta emitido en el Exterior" },
  {
    CODE: "16",
    DESCRIPTION:
      "Formulario Único de Exportación (FUE) o Declaración Aduanera Única (DAU) o Declaración Andina de Valor (DAV)",
  },
  {
    CODE: "18",
    DESCRIPTION: "Documentos autorizados utilizados en ventas excepto N/C N/D",
  },
  { CODE: "19", DESCRIPTION: "Comprobantes de Pago de Cuotas o Aportes" },
  {
    CODE: "20",
    DESCRIPTION:
      "Documentos por Servicios Administrativos emitidos por Inst. del Estado",
  },
  { CODE: "21", DESCRIPTION: "Carta de Porte Aéreo" },
  { CODE: "22", DESCRIPTION: "RECAP" },
  { CODE: "23", DESCRIPTION: "Nota de Crédito TC" },
  { CODE: "24", DESCRIPTION: "Nota de Débito TC" },
  { CODE: "41", DESCRIPTION: "Comprobante de venta emitido por reembolso" },
  {
    CODE: "42",
    DESCRIPTION:
      "Documento retención presuntiva y retención emitida por propio vendedor o por intermediario",
  },
  {
    CODE: "43",
    DESCRIPTION: "Liquidación para Explotación y Exploración de Hidrocarburos",
  },
  { CODE: "44", DESCRIPTION: "Comprobante de Contribuciones y Aportes" },
  { CODE: "45", DESCRIPTION: "Liquidación por reclamos de aseguradoras" },
  {
    CODE: "47",
    DESCRIPTION: "Nota de Crédito por Reembolso Emitida por Intermediario",
  },
  {
    CODE: "48",
    DESCRIPTION: "Nota de Débito por Reembolso Emitida por Intermediario",
  },
  {
    CODE: "49",
    DESCRIPTION: "Proveedor Directo de Exportador Bajo Régimen Especial",
  },
  {
    CODE: "50",
    DESCRIPTION:
      "A Inst. Estado y Empr. Públicas que percibe ingreso exento de Imp. Renta",
  },
  {
    CODE: "51",
    DESCRIPTION:
      "N/C A Inst. Estado y Empr. Públicas que percibe ingreso exento de Imp. Renta",
  },
  {
    CODE: "52",
    DESCRIPTION:
      "N/D A Inst. Estado y Empr. Públicas que percibe ingreso exento de Imp. Renta",
  },
  {
    CODE: "294",
    DESCRIPTION: "Liquidación de compra de Bienes Muebles Usados",
  },
  { CODE: "344", DESCRIPTION: "Liquidación de compra de vehículos usados" },
  { CODE: "364", DESCRIPTION: "Acta Entrega-Recepción PET" },
  { CODE: "370", DESCRIPTION: "Factura operadora transporte / socio" },
  { CODE: "371", DESCRIPTION: "Comprobante socio a operadora de transporte " },
  {
    CODE: "372",
    DESCRIPTION: "Nota de  crédito  operadora transporte / socio",
  },
  { CODE: "373", DESCRIPTION: "Nota de  débito  operadora transporte / socio" },
  { CODE: "374", DESCRIPTION: "Nota de  débito  operadora transporte / socio" },
  {
    CODE: "375",
    DESCRIPTION:
      "Liquidación de compra RISE de bienes o prestación de servicios",
  },
];

export const providerType = [
  { CODE: "01", DESCRIPTION: "PERSONA NATURAL" },
  {
    CODE: "02",
    DESCRIPTION: "SOCIEDAD",
  },
];

export const countryCodeType = [
  { CODE: "593", DESCRIPTION: "ECUADOR" },
  {
    CODE: "118",
    DESCRIPTION: "PANAMA",
  },
  {
    CODE: "105",
    DESCRIPTION: "COLOMBIA",
  },

  {
    CODE: "110",
    DESCRIPTION: "EEUU",
  },
];

export const typeVouchers = [
  {
    CODIGO: "01",
    VERSION: "1.1.0",
    NOMBRE: "Factura",
  },
  {
    CODIGO: "03",
    VERSION: "1.1.0",
    NOMBRE: "Liquidación de compra",
  },
  {
    CODIGO: "04",
    VERSION: "1.1.0",
    NOMBRE: "Nota de crédito",
  },
  {
    CODIGO: "05",
    VERSION: "1.0.0",
    NOMBRE: "Nota de débito",
  },
  {
    CODIGO: "06",
    VERSION: "1.1.0",
    NOMBRE: "Guía de remisión",
  },
  {
    CODIGO: "07",
    VERSION: "1.0.0",
    NOMBRE: "Comprobante de retención",
  },
];
export const typeInvoice = [
  {
    CODIGO: "01",
    VERSION: "1.1.0",
    NOMBRE: "Factura",
  },
  {
    CODIGO: "03",
    VERSION: "1.1.0",
    NOMBRE: "Liquidación de compra",
  },
  {
    CODIGO: "12",
    VERSION: "1.1.0",
    NOMBRE: "Documentos emitidos por instituciones financieras",
  },
  {
    CODIGO: "19",
    VERSION: "1.1.0",
    NOMBRE: "Comprobantes de pago de cuotas o aportes",
  },
];

export const tipoAnio = [
  {
    CODIGO: "2022",
    VALOR: "2022",
    NOMBRE: "2022",
  },
  {
    CODIGO: "2023",
    VALOR: "2023",
    NOMBRE: "2023",
  },
  {
    CODIGO: "2024",
    VALOR: "2024",
    NOMBRE: "2024",
  }, {
    CODIGO: "2025",
    VALOR: "2025",
    NOMBRE: "2025",
  },{
    CODIGO: "2026",
    VALOR: "2026",
    NOMBRE: "2026",
  },
];

export const getTipoAnio = (codigo) => {
  return tipoAnio.find((item) => item.CODIGO === codigo);
};


export const typeSupport = [
  {
    CODIGO: "01",
    VERSION: "1.1.0",
    NOMBRE:
      " Crédito Tributario para declaración de IVA (servicios y bienes distintos de inventarios y activos fijos)",
  },
  {
    CODIGO: "02",
    VERSION: "1.1.0",
    NOMBRE:
      " Costo o Gasto para declaración de IR (servicios y bienes distintos de inventarios y activos fijos)",
  },
  {
    CODIGO: "03",
    VERSION: "1.1.0",
    NOMBRE: "Activo Fijo - Crédito Tributario para declaración de IVA",
  },
  {
    CODIGO: "04",
    VERSION: "1.1.0",
    NOMBRE: "Activo Fijo - Costo o Gasto para declaración de IR",
  },
  {
    CODIGO: "05",
    VERSION: "1.1.0",
    NOMBRE:
      " Liquidación Gastos de Viaje, hospedaje y alimentación Gastos IR (a nombre de empleados y no de la empresa)",
  },
  {
    CODIGO: "06",
    VERSION: "1.1.0",
    NOMBRE: "Inventario - Crédito Tributario para declaración de IVA",
  },
  {
    CODIGO: "07",
    VERSION: "1.1.0",
    NOMBRE: "Inventario - Costo o Gasto para declaración de IR",
  },
  {
    CODIGO: "08",
    VERSION: "1.1.0",
    NOMBRE: " Valor pagado para solicitar Reembolso de Gasto (intermediario)",
  },
  {
    CODIGO: "09",
    VERSION: "1.1.0",
    NOMBRE: "Reembolso por Siniestros",
  },
  {
    CODIGO: "10",
    VERSION: "1.1.0",
    NOMBRE: "Distribución de Dividendos, Beneficios o Utilidades",
  },
  {
    CODIGO: "11",
    VERSION: "1.1.0",
    NOMBRE: "Convenios de débito o recaudación para IFI´s",
  },
  {
    CODIGO: "12",
    VERSION: "1.1.0",
    NOMBRE: "Impuestos y retenciones presuntivos",
  },
  {
    CODIGO: "13",
    VERSION: "1.1.0",
    NOMBRE:
      "Valores reconocidos por entidades del sector público a favor de sujetos pasivos",
  },
  {
    CODIGO: "14",
    VERSION: "1.1.0",
    NOMBRE:
      "Valores facturados por socios a operadoras de transporte (que no constituyen gasto de dicha operadora)",
  },
  {
    CODIGO: "15",
    VERSION: "1.1.0",
    NOMBRE:
      "Pagos efectuados por consumos propios y de terceros de servicios digitales",
  },
  {
    CODIGO: "00",
    VERSION: "1.1.0",
    NOMBRE:
      "Casos especiales cuyo sustento no aplica en las opciones anteriores",
  },
];

export const ivaCodigo = {
  IVA_0: "0",
  IVA_12: "2",
  IVA_15: "4",
  IVA_5: "5",
  IVA_NO: "6",
  IVA_DIF: "8",
}
export const ivaValores = {
  IVA_8: "0.08",
  IVA_12: "0.12",
  IVA_15: "0.15",
  IVA_5: "0.05",
}


export const typeTaxImp = [
  {
    CODIGO: "2",
    NOMBRE: "IVA",
  },
  {
    CODIGO: "3",
    NOMBRE: "ICE",
  },
  // {
  //     CODIGO: '5',
  //     NOMBRE: 'IRBPNR'
  // },
];

//export default class vouchers { };
// valores para el iva
export const valorIva = [
  {
    CODIGO: "0",
    VALOR: 0,
    NOMBRE: "IVA 0%",
  },
  {
    CODIGO: "2",
    VALOR: 12,
    NOMBRE: "IVA 12%",
  },
  {
    CODIGO: "4",
    VALOR: 15,
    NOMBRE: "IVA 15%",
  },
  {
    CODIGO: "6",
    VALOR: 0,
    NOMBRE: "No objeto de impuesto",
  },
  {
    CODIGO: "5",
    VALOR: 5,
    NOMBRE: "IVA 5%",
  },
];

export const getValorIVA = (codigo) => {
  return valorIva.find((item) => item.CODIGO === codigo);
};

//valores para la retención
export const valorRetencion = [
  {
    CODIGO: "0",
    VALOR: 0,
    NOMBRE: "IVA 0%",
  },
  {
    CODIGO: "2",
    VALOR: 12,
    NOMBRE: "IVA 12%",
  },
  {
    CODIGO: "3",
    VALOR: 14,
    NOMBRE: "IVA 14%",
  },
  {
    CODIGO: "6",
    VALOR: 0,
    NOMBRE: "No objeto de impuesto",
  },
];

//valores par el ICE

export const valorICE = [
  {
    CODIGO: "3023",
    VALOR: 150,
    NOMBRE: "ICE - Productos del tabaco sucedáneos del tabaco",
  },
  {
    CODIGO: "3051",
    VALOR: 10,
    NOMBRE: "ICE - Bebidas gaseosas",
  },
  {
    CODIGO: "3610",
    VALOR: 20,
    NOMBRE: "ICE - Perfumes y aguas de tocador",
  },
  {
    CODIGO: "3620",
    VALOR: 35,
    NOMBRE: "ICE - Videojuegos",
  },
  {
    CODIGO: "3630",
    VALOR: 300,
    NOMBRE:
      "ICE - Armas de fuego, armas deportivas y municiones excepto aquellas adquiridas por la fuerza pública",
  },
  {
    CODIGO: "3640",
    VALOR: 100,
    NOMBRE:
      "ICE - Focos incandescentes excepto aquellos utilizados como insumos automotrices",
  },
  {
    CODIGO: "3073",
    VALOR: 5,
    NOMBRE:
      "ICE - Vehículos motorizados cuyo precio de venta al público sea de hasta USD 20.000",
  },
  {
    CODIGO: "3072",
    VALOR: 5,
    NOMBRE:
      "ICE - Camionetas, furgonetas, camiones, y vehículos de rescate cuyo precio de venta al público sea de hasta USD 30.000",
  },
  {
    CODIGO: "3074",
    VALOR: 10,
    NOMBRE:
      "ICE - Vehículos motorizados, excepto camionetas, furgonetas, camiones y de rescate, PVP mayor de USD 20.000 a 30.000",
  },
  {
    CODIGO: "3075",
    VALOR: 15,
    NOMBRE:
      "ICE - Vehículos motorizados, cuyo precio de venta al público sea superior a USD 30.000 y de hasta USD 40.000",
  },
  {
    CODIGO: "3077",
    VALOR: 20,
    NOMBRE:
      "ICE - Vehículos motorizados, cuyo precio de venta al público sea superior a USD 40.000 y de hasta USD 50.000",
  },
  {
    CODIGO: "3078",
    VALOR: 25,
    NOMBRE:
      "ICE - Vehículos motorizados cuyo precio de venta al público sea superior a USD 50.000 y de hasta USD 60.000",
  },
  {
    CODIGO: "3079",
    VALOR: 30,
    NOMBRE:
      "ICE - Vehículos motorizados cuyo precio de venta al público sea superior a USD 60.000 y de hasta USD 70.000",
  },
  {
    CODIGO: "3080",
    VALOR: 35,
    NOMBRE:
      "ICE - Vehículos motorizados cuyo precio de venta al público sea superior a USD 70.000",
  },
  {
    CODIGO: "3171",
    VALOR: 0,
    NOMBRE:
      "ICE - Vehículos híbridos o eléctricos cuyo precio de venta al público sea de hasta USD 35.000",
  },
  {
    CODIGO: "3172",
    VALOR: 8,
    NOMBRE:
      "ICE - Vehículos híbridos o eléctricos cuyo precio de venta al público sea superior a USD 35.000 y de hasta USD 40.000",
  },
  {
    CODIGO: "3173",
    VALOR: 14,
    NOMBRE:
      "ICE - Vehículos híbridos o eléctricos cuyo precio de venta al público sea superior a USD 40.000 y de hasta USD 50.000 ",
  },
  {
    CODIGO: "3174",
    VALOR: 20,
    NOMBRE:
      "ICE - Vehículos híbridos o eléctricos cuyo precio de venta al público sea superior a USD 50.000 y de hasta USD 60.000",
  },
  {
    CODIGO: "3175",
    VALOR: 26,
    NOMBRE:
      "ICE - Vehículos híbridos o eléctricos cuyo precio de venta al público sea superior a USD 60.000 y de hasta USD 70.000",
  },
  {
    CODIGO: "3176",
    VALOR: 32,
    NOMBRE:
      "ICE - Vehículos híbridos o eléctricos cuyo precio de venta al público sea superior a USD 70.000",
  },
  {
    CODIGO: "3181",
    VALOR: 15,
    NOMBRE:
      "ICE - Aviones,avionetas y helicópteros excepto transporte comercial de pasajeros;motos acuáticas,tricares,cuadrones,yates y barcos de recreo",
  },
  {
    CODIGO: "3092",
    VALOR: 15,
    NOMBRE: "ICE - Servicios de televisión pagada ",
  },
  {
    CODIGO: "3650",
    VALOR: 35,
    NOMBRE:
      "ICE - Servicios de casinos, salas de juego (bingo - mecánicos) y otros juegos de azar",
  },
  {
    CODIGO: "3660",
    VALOR: 35,
    NOMBRE:
      "ICE - Las cuotas, membresías, afiliaciones, acciones y similares de los Clubes Sociales,para prestar sus servicios, mayor a USD 1.500 anuales",
  },
  {
    CODIGO: "3011",
    VALOR: 0,
    NOMBRE: "ICE - Cigarrillos rubios ",
  },
  {
    CODIGO: "3021",
    VALOR: 0,
    NOMBRE: "ICE - Cigarrillos negros",
  },
  {
    CODIGO: "3031",
    VALOR: 75,
    NOMBRE: "ICE - Bebidas alcohólicas",
  },
  {
    CODIGO: "3041",
    VALOR: 75,
    NOMBRE: "ICE - Cerveza",
  },
];

export const getValorICE = (codigo) => {
  return valorICE.find((item) => item.CODIGO === codigo);
};

//Formas de pago
export const formasPago = [
  {
    CODIGO: "01",
    NOMBRE: "Sin utilización del sistema financiero",
  },
  {
    CODIGO: "15",
    NOMBRE: "Compensación de deudas",
  },
  {
    CODIGO: "16",
    NOMBRE: "Tarjeta de débito",
  },
  {
    CODIGO: "17",
    NOMBRE: "Dinero electrónico",
  },
  {
    CODIGO: "18",
    NOMBRE: "Tarjeta pre-pago",
  },
  {
    CODIGO: "19",
    NOMBRE: "Tarjeta de crédito",
  },
  {
    CODIGO: "20",
    NOMBRE: "Otros con utilización del sistema financiero",
  },
  {
    CODIGO: "21",
    NOMBRE: "Endoso de títulos",
  },
];

export const unidadTiempo = [
  {
    CODIGO: "01",
    NOMBRE: "Días",
  },
  {
    CODIGO: "02",
    NOMBRE: "Meses",
  },
  {
    CODIGO: "03",
    NOMBRE: "Años",
  },
];

export const typeTax = [
  {
    CODIGO: "1",
    NOMBRE: "Renta",
  },
  {
    CODIGO: "2",
    NOMBRE: "IVA",
  },
  {
    CODIGO: "6",
    NOMBRE: "ISD",
  },
  /*{
        CODIGO: '3',
        NOMBRE: 'ICE'
    },
    {
        CODIGO: '4',
        NOMBRE: 'IRBPNR'
    },
    */
];

export const retentionCodeIva = [
  { CODIGO: "1", VALOR: 30, NOMBRE: "Retención IVA  30%" },
  { CODIGO: "2", VALOR: 70, NOMBRE: "Retención IVA  70%" },
  { CODIGO: "3", VALOR: 100, NOMBRE: "Retención IVA  100%" },
  { CODIGO: "7", VALOR: 0, NOMBRE: "Retención IVA  0%" },
  { CODIGO: "8", VALOR: 0, NOMBRE: "No procede retención" },
  { CODIGO: "9", VALOR: 10, NOMBRE: "Retención IVA  10%" },
  { CODIGO: "10", VALOR: 10, NOMBRE: "Retención IVA  10% bienes" },
  { CODIGO: "11", VALOR: 20, NOMBRE: "Retención IVA  20%" },
];

export const retentionCodeIcd = [
  { CODIGO: "4580", VALOR: 5, NOMBRE: "Retención ICD  30%" },
];

export const retentionCode = [
  {
    CODIGO: "303",
    VALOR: 10,
    NOMBRE:
      "Honorarios profesionales y demás pagos por servicios relacionados con el título profesional",
  },
  {
    CODIGO: "304",
    VALOR: 8,
    NOMBRE:
      "Servicios predomina el intelecto no relacionados con el título profesional",
  },
  {
    CODIGO: "304A",
    VALOR: 8,
    NOMBRE:
      "Comisiones y demás pagos por servicios predomina intelecto no relacionados con el título profesional",
  },
  {
    CODIGO: "304B",
    VALOR: 8,
    NOMBRE:
      "Pagos a notarios y registradores de la propiedad y mercantil por sus actividades ejercidas como tales",
  },
  {
    CODIGO: "304C",
    VALOR: 8,
    NOMBRE:
      "Pagos a deportistas, entrenadores, árbitros, miembros del cuerpo técnico por sus actividades ejercidas como tales",
  },
  {
    CODIGO: "304D",
    VALOR: 8,
    NOMBRE: "Pagos a artistas por sus actividades ejercidas como tales",
  },
  {
    CODIGO: "304E",
    VALOR: 8,
    NOMBRE: "Honorarios y demás pagos por servicios de docencia",
  },
  { CODIGO: "307", VALOR: 2, NOMBRE: "Servicios predomina la mano de obra" },
  {
    CODIGO: "308",
    VALOR: 10,
    NOMBRE: "Utilización o aprovechamiento de la imagen o renombre",
  },
  {
    CODIGO: "309",
    VALOR: 1.75,
    NOMBRE:
      "Servicios prestados por medios de comunicación y agencias de publicidad",
  },
  {
    CODIGO: "310",
    VALOR: 1,
    NOMBRE:
      "Servicio de transporte privado de pasajeros o transporte público o privado de carga",
  },
  {
    CODIGO: "311",
    VALOR: 2,
    NOMBRE:
      "Pagos a través de liquidación de compra (nivel cultural o rusticidad)",
  },
  {
    CODIGO: "312",
    VALOR: 1.75,
    NOMBRE: "Transferencia de bienes muebles de naturaleza corporal",
  },
  {
    CODIGO: "312A",
    VALOR: 1,
    NOMBRE:
      "Compra de bienes de origen agrícola, avícola, pecuario, apícola, cunícula, bioacuático, forestal y carnes en estado natural",
  },
  {
    CODIGO: "312B",
    VALOR: 1,
    NOMBRE:
      "Impuesto a la Renta único para la actividad de producción y cultivo de palma aceitera",
  },
  {
    CODIGO: "314A",
    VALOR: 8,
    NOMBRE:
      "Regalías por concepto de franquicias de acuerdo a Ley de Propiedad Intelectual - pago a personas naturales",
  },
  {
    CODIGO: "314B",
    VALOR: 8,
    NOMBRE:
      "Cánones, derechos de autor,  marcas, patentes y similares de acuerdo a Ley de Propiedad Intelectual – pago a personas naturales",
  },
  {
    CODIGO: "314C",
    VALOR: 8,
    NOMBRE:
      "Regalías por concepto de franquicias de acuerdo a Ley de Propiedad Intelectual  - pago a sociedades",
  },
  {
    CODIGO: "314D",
    VALOR: 8,
    NOMBRE:
      "Cánones, derechos de autor,  marcas, patentes y similares de acuerdo a Ley de Propiedad Intelectual – pago a sociedades",
  },
  {
    CODIGO: "319",
    VALOR: 1.75,
    NOMBRE:
      "Cuotas de arrendamiento mercantil (prestado por sociedades), inclusive la de opción de compra",
  },
  { CODIGO: "320", VALOR: 8, NOMBRE: "Arrendamiento bienes inmuebles" },
  {
    CODIGO: "322",
    VALOR: 1.75,
    NOMBRE: "Seguros y reaseguros (primas y cesiones)",
  },
  {
    CODIGO: "323",
    VALOR: 2,
    NOMBRE:
      "Rendimientos financieros pagados a naturales y sociedades  (No a IFIs)",
  },
  {
    CODIGO: "323A",
    VALOR: 2,
    NOMBRE: "Rendimientos financieros: depósitos Cta. Corriente",
  },
  {
    CODIGO: "323B1",
    VALOR: 2,
    NOMBRE: "Rendimientos financieros:  depósitos Cta. Ahorros Sociedades",
  },
  {
    CODIGO: "323E",
    VALOR: 2,
    NOMBRE: "Rendimientos financieros: depósito a plazo fijo  gravados",
  },
  {
    CODIGO: "323E2",
    VALOR: 0,
    NOMBRE: "Rendimientos financieros: depósito a plazo fijo exentos",
  },
  {
    CODIGO: "323F",
    VALOR: 2,
    NOMBRE: "Rendimientos financieros: operaciones de reporto - repos",
  },
  {
    CODIGO: "323G",
    VALOR: 2,
    NOMBRE:
      "Inversiones (captaciones) rendimientos distintos de aquellos pagados a IFIs",
  },
  {
    CODIGO: "323H",
    VALOR: 2,
    NOMBRE: "Rendimientos financieros: obligaciones",
  },
  {
    CODIGO: "323I",
    VALOR: 2,
    NOMBRE: "Rendimientos financieros: bonos convertible en acciones",
  },
  {
    CODIGO: "323 M",
    VALOR: 2,
    NOMBRE:
      "Rendimientos financieros: Inversiones en títulos valores en renta fija gravados ",
  },
  {
    CODIGO: "323 N",
    VALOR: 0,
    NOMBRE:
      "Rendimientos financieros: Inversiones en títulos valores en renta fija exentos",
  },
  {
    CODIGO: "323 O",
    VALOR: 0,
    NOMBRE:
      "Intereses y demás rendimientos financieros pagados a bancos y otras entidades sometidas al control de la Superintendencia de Bancos y de la Economía Popular y Solidaria",
  },
  {
    CODIGO: "323 P",
    VALOR: 2,
    NOMBRE:
      "Intereses pagados por entidades del sector público a favor de sujetos pasivos",
  },
  {
    CODIGO: "323Q",
    VALOR: 2,
    NOMBRE: "Otros intereses y rendimientos financieros gravados ",
  },
  {
    CODIGO: "323R",
    VALOR: 0,
    NOMBRE: "Otros intereses y rendimientos financieros exentos",
  },
  {
    CODIGO: "323S",
    VALOR: 2,
    NOMBRE:
      "Pagos y créditos en cuenta efectuados por el BCE y los depósitos centralizados de valores, en calidad de intermediarios, a instituciones del sistema financiero por cuenta de otras personas naturales y sociedades",
  },
  {
    CODIGO: "323T",
    VALOR: 0,
    NOMBRE:
      "Rendimientos financieros originados en la deuda pública ecuatoriana",
  },
  {
    CODIGO: "323U",
    VALOR: 0,
    NOMBRE:
      "Rendimientos financieros originados en títulos valores de obligaciones de 360 días o más para el financiamiento de proyectos públicos en asociación público-privada",
  },
  {
    CODIGO: "324A",
    VALOR: 1,
    NOMBRE:
      "Intereses y comisiones en operaciones de crédito entre instituciones del sistema financiero y entidades economía popular y solidaria.",
  },
  {
    CODIGO: "324B",
    VALOR: 1,
    NOMBRE:
      "Inversiones entre instituciones del sistema financiero y entidades economía popular y solidaria",
  },
  {
    CODIGO: "324C",
    VALOR: 1,
    NOMBRE:
      "Pagos y créditos en cuenta efectuados por el BCE y los depósitos centralizados de valores, en calidad de intermediarios, a instituciones del sistema financiero por cuenta de otras instituciones del sistema financiero",
  },
  {
    CODIGO: "328",
    VALOR: 0,
    NOMBRE: "Dividendos distribuidos a sociedades residentes",
  },
  {
    CODIGO: "329",
    VALOR: 0,
    NOMBRE: "Dividendos distribuidos a fideicomisos residentes",
  },
  {
    CODIGO: "331",
    VALOR: 0,
    NOMBRE: "Dividendos en acciones (capitalización de utilidades)",
  },
  {
    CODIGO: "332",
    VALOR: 0,
    NOMBRE: "Otras compras de bienes y servicios no sujetas a retención",
  },
  { CODIGO: "332B", VALOR: 0, NOMBRE: "Compra de bienes inmuebles" },
  { CODIGO: "332C", VALOR: 0, NOMBRE: "Transporte público de pasajeros" },
  {
    CODIGO: "332D",
    VALOR: 0,
    NOMBRE:
      "Pagos en el país por transporte de pasajeros o transporte internacional de carga, a compañías nacionales o extranjeras de aviación o marítimas",
  },
  {
    CODIGO: "332E",
    VALOR: 0,
    NOMBRE:
      "Valores entregados por las cooperativas de transporte a sus socios",
  },
  {
    CODIGO: "332F",
    VALOR: 0,
    NOMBRE:
      "Compraventa de divisas distintas al dólar de los Estados Unidos de América",
  },
  { CODIGO: "332G", VALOR: 0, NOMBRE: "Pagos con tarjeta de crédito " },
  {
    CODIGO: "332H",
    VALOR: 0,
    NOMBRE:
      "Pago al exterior tarjeta de crédito reportada por la Emisora de tarjeta de crédito, solo RECAP",
  },
  {
    CODIGO: "332I",
    VALOR: 0,
    NOMBRE: "Pago a través de convenio de debito (Clientes IFI`s)",
  },
  {
    CODIGO: "333",
    VALOR: 10,
    NOMBRE:
      "Ganancia en la enajenación de derechos representativos de capital u otros derechos que permitan la exploración, explotación, concesión o similares de sociedades, que se coticen en bolsa de valores del Ecuador",
  },
  {
    CODIGO: "334",
    VALOR: 1,
    NOMBRE:
      "Contraprestación producida por la enajenación de derechos representativos de capital u otros derechos que permitan la exploración, explotación, concesión o similares de sociedades, no cotizados en bolsa de valores del Ecuador",
  },
  { CODIGO: "335", VALOR: 15, NOMBRE: "Loterías, rifas, apuestas y similares" },
  {
    CODIGO: "346",
    VALOR: 1.75,
    NOMBRE: "Otras retenciones aplicables a otros porcentajes ",
  },
  {
    CODIGO: "340",
    VALOR: 3,
    NOMBRE: "Impuesto único a la exportación de banano",
  },
  { CODIGO: "343", VALOR: 1, NOMBRE: "Otras retenciones aplicables el 1%" },
  { CODIGO: "343A", VALOR: 1, NOMBRE: "Energía eléctrica" },
  {
    CODIGO: "343B",
    VALOR: 1.75,
    NOMBRE:
      "Actividades de construcción de obra material inmueble, urbanización, lotización o actividades similares",
  },
  {
    CODIGO: "343C",
    VALOR: 1,
    NOMBRE: "Impuesto Redimible a las botellas plásticas - IRBP",
  },
  {
    CODIGO: "3440",
    VALOR: 2.75,
    NOMBRE: "Otras retenciones aplicables el 2,75%",
  },
  {
    CODIGO: "344A",
    VALOR: 2,
    NOMBRE:
      "Pago local tarjeta de crédito /débito reportada por la Emisora de tarjeta de crédito / entidades del sistema financiero",
  },
  {
    CODIGO: "344B",
    VALOR: 2,
    NOMBRE:
      "Adquisición de sustancias minerales dentro del territorio nacional",
  },
  { CODIGO: "345", VALOR: 8, NOMBRE: "Otras retenciones aplicables el 8%" },
  {
    CODIGO: "348",
    VALOR: 1,
    NOMBRE:
      "Impuesto único a ingresos provenientes de actividades agropecuarias en etapa de producción / comercialización local o exportación",
  },
  { CODIGO: "351", VALOR: 1.75, NOMBRE: "Régimen Microempresarial" },
  {
    CODIGO: "504",
    VALOR: 25,
    NOMBRE:
      "Pago a no residentes- Dividendos distribuidos a personas naturales (domicilados o no en paraiso fiscal) o a sociedades sin beneficiario efectivo persona natural residente en Ecuador",
  },
  {
    CODIGO: "504B",
    VALOR: 35,
    NOMBRE:
      "Dividendos a no residentes incumpliendo el deber de informar la composición societaria",
  },
  {
    CODIGO: "505D",
    VALOR: 25,
    NOMBRE:
      "Pago a no residentes - Intereses por financiamiento de proveedores externos",
  },
  {
    CODIGO: "505E",
    VALOR: 25,
    NOMBRE: "Pago a no residentes - Intereses de otros créditos externos",
  },
  {
    CODIGO: "520E",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes - Por las empresas de transporte marítimo o aéreo y por empresas pesqueras de alta mar, por su actividad.",
  },
  { CODIGO: "325", VALOR: 22, NOMBRE: "Anticipo dividendos" },
  {
    CODIGO: "325A",
    VALOR: 22,
    NOMBRE:
      "Préstamos accionistas, beneficiarios o partícipes residentes o establecidos en el Ecuador",
  },
  {
    CODIGO: "326",
    VALOR: 0,
    NOMBRE:
      "Dividendos distribuidos que correspondan al impuesto a la renta único establecido en el art. 27 de la LRTI ",
  },
  {
    CODIGO: "327",
    VALOR: 0,
    NOMBRE: "Dividendos distribuidos a personas naturales residentes",
  },
  {
    CODIGO: "336",
    VALOR: 0,
    NOMBRE: "Venta de combustibles a comercializadoras",
  },
  { CODIGO: "337", VALOR: 0, NOMBRE: "Venta de combustibles a distribuidores" },
  {
    CODIGO: "338",
    VALOR: 0,
    NOMBRE:
      "Producción y venta local de banano producido o no por el mismo sujeto pasivo",
  },
  {
    CODIGO: "346A",
    VALOR: 0,
    NOMBRE:
      "Otras ganancias de capital distintas de enajenación de derechos representativos de capital ",
  },
  //{CODIGO:"346B",VALOR:Según art 36 LRTI literal d),NOMBRE:"Donaciones en dinero -Impuesto a la donaciones "},
  //{CODIGO:"346C",VALOR: 0 ó 10,NOMBRE:"Retención a cargo del propio sujeto pasivo por la exportación de concentrados y/o elementos metálicos"},
  //{CODIGO:"346D",VALOR: 0 ó 10,NOMBRE:"Retención a cargo del propio sujeto pasivo por la comercialización de productos forestales"},
  //{CODIGO:"350",VALOR:1,50 ó 1,75,NOMBRE:"Otras autoretenciones"},
  {
    CODIGO: "500",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Rentas Inmobiliarias",
  },
  {
    CODIGO: "501",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Beneficios/Servicios  Empresariales",
  },
  //{CODIGO:"501A",VALOR:25 ó 35,NOMBRE:"Pago a no residentes - Servicios técnicos, administrativos o de consultoría y regalías"},
  {
    CODIGO: "503",
    VALOR: 0,
    NOMBRE: "Pago a no residentes- Navegación Marítima y/o aérea",
  },
  {
    CODIGO: "504A",
    VALOR: 0,
    NOMBRE:
      "Dividendos a sociedades con beneficiario efectivo persona natural residente en el Ecuador",
  },
  {
    CODIGO: "504C",
    VALOR: 0,
    NOMBRE:
      "Dividendos a residentes o establecidos en paraísos fiscales o regímenes de menor imposición (con beneficiario Persona Natural residente en Ecuador)",
  },
  {
    CODIGO: "504D",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes - Dividendos a fideicomisos domiciladas en paraísos fiscales o regímenes de menor imposición (con beneficiario efectivo persona natural residente en el Ecuador)",
  },
  {
    CODIGO: "504E",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes - Anticipo dividendos (no domiciliada en paraísos fiscales o regímenes de menor imposición)",
  },
  {
    CODIGO: "504F",
    VALOR: 13,
    NOMBRE:
      "Pago a no residentes - Anticipo dividendos (domiciliadas en paraísos fiscales o regímenes de menor imposición)",
  },
  {
    CODIGO: "504G",
    VALOR: 25,
    NOMBRE:
      "Pago a no residentes - Préstamos accionistas, beneficiarios o partìcipes (no domiciladas en paraísos fiscales o regímenes de menor imposición)",
  },
  {
    CODIGO: "504H",
    VALOR: 13,
    NOMBRE:
      "Pago a no residentes - Préstamos accionistas, beneficiarios o partìcipes (domiciladas en paraísos fiscales o regímenes de menor imposición)",
  },
  //{CODIGO:"504I",VALOR:22 ó 25,NOMBRE:"Pago a no residentes - Préstamos no comerciales a partes relacionadas  (no domiciladas en paraísos fiscales o regímenes de menor imposición)"},
  //{CODIGO:"504J",VALOR:22, 25 ó 28,NOMBRE:"Pago a no residentes - Préstamos no comerciales a partes relacionadas  (domiciladas en paraísos fiscales o regímenes de menor imposición)"},
  {
    CODIGO: "505",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Rendimientos financieros",
  },
  {
    CODIGO: "505A",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes – Intereses de créditos de Instituciones Financieras del exterior",
  },
  {
    CODIGO: "505B",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes – Intereses de créditos de gobierno a gobierno",
  },
  {
    CODIGO: "505C",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes – Intereses de créditos de organismos multilaterales",
  },
  {
    CODIGO: "505F",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Otros Intereses y Rendimientos Financieros",
  },
  {
    CODIGO: "509",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes- Cánones, derechos de autor,  marcas, patentes y similares",
  },
  {
    CODIGO: "509A",
    VALOR: 0,
    NOMBRE: "PPago a no residentes - Regalías por concepto de franquicias",
  },
  {
    CODIGO: "510",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes - Otras ganancias de capital distintas de enajenación de derechos representativos de capital ",
  },
  {
    CODIGO: "511",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Servicios profesionales independientes",
  },
  {
    CODIGO: "512",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Servicios profesionales dependientes",
  },
  { CODIGO: "513", VALOR: 0, NOMBRE: "Pago a no residentes- Artistas" },
  { CODIGO: "513A", VALOR: 0, NOMBRE: "Pago a no residentes - Deportistas" },
  {
    CODIGO: "514",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Participación de consejeros",
  },
  {
    CODIGO: "515",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Entretenimiento Público",
  },
  { CODIGO: "516", VALOR: 0, NOMBRE: "Pago a no residentes - Pensiones" },
  {
    CODIGO: "517",
    VALOR: 0,
    NOMBRE: "Pago a no residentes- Reembolso de Gastos",
  },
  {
    CODIGO: "518",
    VALOR: 0,
    NOMBRE: "Pago a no residentes- Funciones Públicas",
  },
  { CODIGO: "519", VALOR: 0, NOMBRE: "Pago a no residentes - Estudiantes" },
  {
    CODIGO: "520A",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes - Pago a proveedores de servicios hoteleros y turísticos en el exterior",
  },
  {
    CODIGO: "520B",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Arrendamientos mercantil internacional",
  },
  {
    CODIGO: "520D",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes - Comisiones por exportaciones y por promoción de turismo receptivo",
  },
  {
    CODIGO: "520F",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Por las agencias internacionales de prensa",
  },
  {
    CODIGO: "520G",
    VALOR: 0,
    NOMBRE:
      "Pago a no residentes - Contratos de fletamento de naves para empresas de transporte aéreo o marítimo internacional",
  },
  {
    CODIGO: "521",
    VALOR: 5,
    NOMBRE:
      "Pago a no residentes - Enajenación de derechos representativos de capital u otros derechos que permitan la exploración, explotación, concesión o similares de sociedades",
  },
  {
    CODIGO: "523A",
    VALOR: 0,
    NOMBRE: "Pago a no residentes - Seguros y reaseguros (primas y cesiones)  ",
  },
  //{CODIGO:"525",VALOR:Según art 36 LRTI literal d),NOMBRE:"Pago a no residentes- Donaciones en dinero -Impuesto a la donaciones"},
];
